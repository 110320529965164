










































import { Component, Vue } from 'vue-property-decorator';

import DistributeSpecialContributionSuccessWealthViewModel
  from '@/vue-app/view-models/allianz-dashboard/goals/distribute-special-contribution-success-view-model';

@Component({
  name: 'ModalDistributeSpecialContributionSuccessWealth',
})
export default class DistributeSpecialContributionSuccessWealth extends Vue {
  distribute_special_contribution_success_view_model = Vue.observable(
    new DistributeSpecialContributionSuccessWealthViewModel(this),
  );
}
