import TYPES from '@/types';

import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class DistributeSpecialContributionSuccessWealthViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.modal-distribute-special-contribution-success-wealth';

  private readonly view: Vue;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  constructor(view: Vue) {
    this.view = view;
  }

  accept = () => {
    this.view.$emit('accept');
  }
}
