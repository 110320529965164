












































































































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import DistributeBalanceUnallocatedWealthViewModel
  from '@/vue-app/view-models/allianz-dashboard/goals/distribute-balance-unallocated-wealth-view-model';
import DistributeSpecialContributionSuccessWealth
  from '@/vue-app/components/allianz-dashboard/goals/DistributeSpecialContributionSuccessWealth.vue';

@Component({
  name: 'DistributeBalanceUnallocatedWealthModal',
  components: {
    DistributeSpecialContributionSuccessWealth,
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class DistributeBalanceUnallocatedWealthModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  distribute_balance_unallocated_wealth_view_model = Vue.observable(
    new DistributeBalanceUnallocatedWealthViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  created() {
    this.distribute_balance_unallocated_wealth_view_model.initialize();
  }
}
